// Generated by Framer (92f3d02)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const ImageWithFX = withFX(Image);

const cycleOrder = ["jmFb1vvCN", "tfs5E6Dhf"];

const serializationHash = "framer-Ubj58"

const variantClassNames = {jmFb1vvCN: "framer-v-ff4nop", tfs5E6Dhf: "framer-v-1owrehn"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Portrait (Uknown)": "jmFb1vvCN", "Square 1:1": "tfs5E6Dhf"}

const getProps = ({height, id, image, width, ...props}) => { return {...props, q5HSGL3ff: image ?? props.q5HSGL3ff ?? {src: "https://framerusercontent.com/images/a8oV19RnGCUj6KvO06x9p8ni0.png?scale-down-to=1024", srcSet: "https://framerusercontent.com/images/a8oV19RnGCUj6KvO06x9p8ni0.png?scale-down-to=1024 674w,https://framerusercontent.com/images/a8oV19RnGCUj6KvO06x9p8ni0.png?scale-down-to=2048 1348w,https://framerusercontent.com/images/a8oV19RnGCUj6KvO06x9p8ni0.png 2696w"}, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "jmFb1vvCN"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, q5HSGL3ff, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "jmFb1vvCN", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-ff4nop", className, classNames)} data-framer-name={"Portrait (Uknown)"} layoutDependency={layoutDependency} layoutId={"jmFb1vvCN"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({tfs5E6Dhf: {"data-framer-name": "Square 1:1"}}, baseVariant, gestureVariant)}><ImageWithFX __framer__adjustPosition __framer__offset={800} __framer__parallaxTransformEnabled __framer__speed={105} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} background={{alt: "", fit: "fill", pixelHeight: 4096, pixelWidth: 2696, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/a8oV19RnGCUj6KvO06x9p8ni0.png", srcSet: "https://framerusercontent.com/images/a8oV19RnGCUj6KvO06x9p8ni0.png?scale-down-to=1024 674w,https://framerusercontent.com/images/a8oV19RnGCUj6KvO06x9p8ni0.png?scale-down-to=2048 1348w,https://framerusercontent.com/images/a8oV19RnGCUj6KvO06x9p8ni0.png 2696w"}} className={"framer-3yf0iy"} data-framer-name={"image"} layoutDependency={layoutDependency} layoutId={"sxxljGUbd"} {...addPropertyOverrides({tfs5E6Dhf: {background: {alt: "", fit: "fill", pixelHeight: 4096, pixelWidth: 2696, sizes: componentViewport?.width || "100vw", ...toResponsiveImage(q5HSGL3ff), ...{ positionX: "center", positionY: "center" }}}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Ubj58.framer-mpieay, .framer-Ubj58 .framer-mpieay { display: block; }", ".framer-Ubj58.framer-ff4nop { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 771px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 620px; }", ".framer-Ubj58 .framer-3yf0iy { bottom: -100px; flex: none; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 1px; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Ubj58.framer-ff4nop { gap: 0px; } .framer-Ubj58.framer-ff4nop > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-Ubj58.framer-ff4nop > :first-child { margin-top: 0px; } .framer-Ubj58.framer-ff4nop > :last-child { margin-bottom: 0px; } }", ".framer-Ubj58.framer-v-1owrehn.framer-ff4nop { aspect-ratio: 1 / 1; height: var(--framer-aspect-ratio-supported, 720px); width: 720px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 770.5
 * @framerIntrinsicWidth 620
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"tfs5E6Dhf":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"q5HSGL3ff":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerMB2hnvq4p: React.ComponentType<Props> = withCSS(Component, css, "framer-Ubj58") as typeof Component;
export default FramerMB2hnvq4p;

FramerMB2hnvq4p.displayName = "Floating Images Speed";

FramerMB2hnvq4p.defaultProps = {height: 770.5, width: 620};

addPropertyControls(FramerMB2hnvq4p, {variant: {options: ["jmFb1vvCN", "tfs5E6Dhf"], optionTitles: ["Portrait (Uknown)", "Square 1:1"], title: "Variant", type: ControlType.Enum}, q5HSGL3ff: {__defaultAssetReference: "data:framer/asset-reference,a8oV19RnGCUj6KvO06x9p8ni0.png?originalFilename=sakura-shopify-template-02.png&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerMB2hnvq4p, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})